<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Teams",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    TeamList: () => import('@/components/lazy/teams/list/TeamList'),
    InviteList: () => import('@/components/lazy/teams/list/InviteList'),
  },
  validations: {},
  methods: {},
  created() {

  },
  computed: {
    teamsVisible() {
      return this.$store.getters.getTeams().length || this.$store.getters.getSubscriberStatus() || !this.$store.getters.getTeamInvites().length
    }
  },
  data() {
    return {}
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center">
      <h4 class="mb-0 font-size-18">
        {{ teamsVisible ? $t('menu.teams.title') : $t('menu.invites.title') }}
      </h4>
      <!--
      TODO: Asses this placeholder
      <div v-if="!teamsVisible" class="ml-1 badge badge-primary">
        {{ $t('invite badge placeholder') }}
      </div>
      -->
      <div class="page-title-right bv-d-xs-down-none ml-auto">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link to="/">
              CFTools Cloud
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span aria-current="location">
              {{ $t('menu.teams.title') }}
            </span>
          </li>
        </ol>
      </div>
    </div>
    <TeamList v-if="teamsVisible"></TeamList>
    <template v-if="$store.getters.getTeamInvites().length">
      <div v-if="teamsVisible" class="page-title-box page-subtitle d-flex align-items-center">
        <h4 class="mb-0 font-size-18">
          {{ $t('menu.invites.title') }}
        </h4>
        <!--
        TODO: Asses this placeholder
        <div class="ml-1 badge badge-primary">
          {{ $t('invite badge placeholder') }}
        </div>
        -->
      </div>
      <InviteList></InviteList>
    </template>
  </Layout>
</template>